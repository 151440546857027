/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export type InstantSearchResultListView = {
    id: number;
    title: string;
    thumbnail: string;
    url: string;
    contentItemType: string;
    activationTime: DateTime | null;
    authorInfo: string | null;
}

export const parseInstantSearchResultListView = (data: any): InstantSearchResultListView => {
    return {
        id: ensureNonNull<number>(data.id, "id is required"),
        title: ensureNonNull<string>(data.title, "title is required"),
        thumbnail: ensureNonNull<string>(data.thumbnail, "thumbnail is required"),
        url: ensureNonNull<string>(data.url, "url is required"),
        contentItemType: ensureNonNull<string>(data.contentItemType, "contentItemType is required"),
        activationTime: data.activationTime == null ? null : DateTime.fromISO(ensureNonNull<string>(data.activationTime, " is required")),
        authorInfo: data.authorInfo == null ? null : data.authorInfo,
    }
}

export const renderInstantSearchResultListView = (data: InstantSearchResultListView): any => {
    return {
        id: data.id,
        title: data.title,
        thumbnail: data.thumbnail,
        url: data.url,
        contentItemType: data.contentItemType,
        activationTime:data.activationTime == null ? null :  ensureNonNull<DateTime>(data.activationTime, "activationTime is required").toUTC().toISO(),
        authorInfo: data.authorInfo == null ? null : data.authorInfo,
    }
}


