/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { type InstantSearchResultListView, parseInstantSearchResultListView, renderInstantSearchResultListView } from './instantSearchResultListView';
// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export type InstantSearchResult = {
    SUMMARY: Array<InstantSearchResultListView> | null;
    CHANNEL: Array<InstantSearchResultListView> | null;
    CUSTOMPAGE: Array<InstantSearchResultListView> | null;
    ACTIONABLE: Array<InstantSearchResultListView> | null;
}

export const parseInstantSearchResult = (data: any): InstantSearchResult => {
    return {
        SUMMARY: data.SUMMARY == null ? null : ensureNonNull<Array<InstantSearchResultListView>>(data.SUMMARY, "SUMMARY is required").map((value: any) => parseInstantSearchResultListView(value)),
        CHANNEL: data.CHANNEL == null ? null : ensureNonNull<Array<InstantSearchResultListView>>(data.CHANNEL, "CHANNEL is required").map((value: any) => parseInstantSearchResultListView(value)),
        CUSTOMPAGE: data.CUSTOMPAGE == null ? null : ensureNonNull<Array<InstantSearchResultListView>>(data.CUSTOMPAGE, "CUSTOMPAGE is required").map((value: any) => parseInstantSearchResultListView(value)),
        ACTIONABLE: data.ACTIONABLE == null ? null : ensureNonNull<Array<InstantSearchResultListView>>(data.ACTIONABLE, "ACTIONABLE is required").map((value: any) => parseInstantSearchResultListView(value)),
    }
}

export const renderInstantSearchResult = (data: InstantSearchResult): any => {
    return {
        SUMMARY: data.SUMMARY == null ? null : ensureNonNull<Array<InstantSearchResultListView>>(data.SUMMARY, "SUMMARY is required").map((value: any) => renderInstantSearchResultListView(value)),
        CHANNEL: data.CHANNEL == null ? null : ensureNonNull<Array<InstantSearchResultListView>>(data.CHANNEL, "CHANNEL is required").map((value: any) => renderInstantSearchResultListView(value)),
        CUSTOMPAGE: data.CUSTOMPAGE == null ? null : ensureNonNull<Array<InstantSearchResultListView>>(data.CUSTOMPAGE, "CUSTOMPAGE is required").map((value: any) => renderInstantSearchResultListView(value)),
        ACTIONABLE: data.ACTIONABLE == null ? null : ensureNonNull<Array<InstantSearchResultListView>>(data.ACTIONABLE, "ACTIONABLE is required").map((value: any) => renderInstantSearchResultListView(value)),
    }
}


